import jobsdaheimLogo from "@/images/jobsdaheim/logo-wide.png"
import sisaxLogo from "@/images/sisax/Silicon_Saxony_logo.svg"
import ddfLogo from "@/images/ddf/logo.png"
import jobs4iLogo from "@/images/jobs4i/logo-wide.svg"
import personalforumLogo from '@/images/personalforum/logo-wide.png'
import settings from "@/generated/settings.json"

const logos = {
  personalforum: personalforumLogo,
  jobsdaheim: jobsdaheimLogo,
  sisax: sisaxLogo,
  ddf: ddfLogo,
  jobs4i: jobs4iLogo,
}

const logo = logos[settings.key] as string

console.log(logo)

export {
  logo,
}

import { createApp, h } from "vue"
import { createInertiaApp } from "@inertiajs/inertia-vue3"


import VueTippy from "vue-tippy"
import "tippy.js/dist/tippy.css" // optional for styling
import "tippy.js/themes/material.css"
import "tippy.js/animations/shift-away.css"

export function removeModulePrefix<T>(object: Record<string, T>) {
  return Object.fromEntries(
    Object.keys(object).map((key) => {
      return [key.replace(/^..\/pages\/[^\/]+\//, ""), object[key]]
    })
  )
}

export function startApp(modules: Record<string, Function>, layout, i18n) {
  createInertiaApp({
    resolve: (name) => {
      const m = modules[`${name}.vue`]
      if (!m) {
        console.error("available pages: ", Object.keys(modules))
        throw new Error(`Page not found: ${name}`)
      }
      const r = m()
      return r.then((page) => {
        if (page.default.layout === undefined) page.default.layout = layout
        return page
      })
    },
    setup({ el, app, props, plugin }) {
      createApp({ render: () => h(app, props) })
        .use(plugin)
        .use(i18n)
        .use(VueTippy, {
          directive: "tooltip",
          component: "tippy",
          componentSingleton: "tippy-singleton",
          defaultProps: {
            theme: "material",
            animation: "shift-away",
            placement: "auto-end",
            allowHTML: true,
          },
        })
        .mount(el)
    },
  })
}

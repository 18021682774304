export default {
  "de": {
    "competences_controller": {
      "alle_stellen_rund_um": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle Stellen rund um ", _interpolate(_named("competence_keywords_join"))])}
    },
    "components": {
      "applicant_service": {
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner für internationale Bewerber"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services für Internationals"])},
        "unternehmenssprachen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmenssprachen"])}
      },
      "apply_card": {
        "beantworten_sie_bitte_folg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Beantworten Sie bitte folgende Fragen der ", _interpolate(_named("job_organisation_name")), ":"])},
        "bms_privacy_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Organisation ", _interpolate(_named("organisation")), " verwendet zur Verarbeitung deiner Bewerbung ein Bewerbermanagementsystem. Die Datenschutzerklärungen zur Speicherung und Verarbeitung deiner Daten <a href=\"", _interpolate(_named("privacyPolicyUrl")), "\" target=\"_blank\" class=\"text-primary-500 hover:text-primary-700\" style=\"text-decoration: underline\">findest du hier</a>."])},
        "error_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Angaben sind noch unvollständig:"])},
        "external_hint": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["WICHTIGER HINWEIS: Die ", _interpolate(_named("company")), " nutzt ein Bewerbermanagementsystem. Du wirst mit Klick auf den Button „Bewerben“ direkt dorthin geleitet. Dazu öffnet sich ein neues Fenster/Tab."])},
        "gender_options": {
          "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers"])},
          "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
          "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])}
        },
        "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bewirb dich sich jetzt bei der ", _interpolate(_named("company")), " als ", _interpolate(_named("job")), "."])},
        "kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Arbeitgeber bittet dich, in der Zwischenzeit auf kanaleo ein anonymes Feedback zu geben, wie du auf die Stelle aufmerksam geworden sbist."])},
        "living_in_germany_options": {
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich wohne in einem <b>anderen Land</b>"])},
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich lebe zur Zeit <b>in Deutschland</b> / mein offizieller Wohnsitz befindet sich in Deutschland"])}
        },
        "postal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postanschrift"])},
        "previous_applied": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Du hast sich bereits am ", _interpolate(_named("date")), " auf diese Stelle beworben."])},
        "privacy_hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datenschutzbestimmungen des Empfehlungsbund und seiner Job-Communitys findest du <a class=\"text-primary-500 hover:text-primary-700\" href=\"https://www.empfehlungsbund.de/datenschutz#bewerbungsformular\" target='_blank'>hier</a>. deiner Daten werden hier nur zwischengespeichert, und nach erfolgreicher Übermittlung an die Organisation gelöscht."])},
        "required_documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vom Unternehmen geforderte Dokumente:"])},
        "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte prüfen Sie, ob Sie diese Grundanforderungen erfüllen:"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung abschicken"])},
        "succeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbung wurde erfolgreich versendet!"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerben"])},
        "zu_kanaleo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Kanaleo"])}
      },
      "empfehlungscodes_field": {
        "active_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Empfehlungscodes:"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode hinzufügen"])},
        "did_you_receive_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie einen"])},
        "did_you_receive_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode"])},
        "did_you_receive_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erhalten?"])},
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Empfehlungscode erhalten nur erstklassige Fachkräfte. Die Unternehmer der beteiligten Community-Portale verständigen sich regelmäßig,\nwer eine Empfehlung verdient hat. Vertraue  dem Urteil der Experten. Überzeuge dich vom strengen Regelwerk in den\n<a class=\"text-blue-500 hover:text-blue-700\" href=\"https://www.empfehlungsbund.de/faq#Bewerber\" target=\"_blank\">FAQs des Empfehlungsbunds</a>.\nNutze den Empfehlungscode für deinen beruflichen Erfolg.\n"])},
        "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empfehlungscode hinzufügen"])},
        "recommended_by": {
          "referenzcode": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Empfohlener Kandidat, von ", _interpolate(_named("company"))])},
          "referenzcode2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Interessanter Kandidat, von ", _interpolate(_named("company"))])}
        },
        "use_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Referenz verwenden: Die Firma bei der mich hier bewerbe, darf gern die Organisation, die mir den Empfehlungscode ausgestellt hat, während meiner Bewerbung zu den Gründen der Empfehlung befragen."])}
      },
      "filter_sidebar": {
        "job_type": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Arten"])},
          "interns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lernende/Praktika"])},
          "professionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fach- & Führungskräfte"])}
        },
        "jobs4i": {
          "low_german": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich spreche kein oder nur wenig Deutsch (A1-A2)"])},
          "target_audience_country": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Wohnort"])},
            "options": {
              "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU/EWR"])},
              "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
              "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Länder/Dritte Staaten"])}
            }
          },
          "target_audience_work_permit": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du bereits eine Arbeitserlaubnis für Deutschland bzw. die EU?"])},
            "options": {
              "germany_eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ich habe eine Arbeitserlaubnis"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe noch keine Arbeitserlaubnis"])}
            }
          },
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter für Stellenangebote"])}
        },
        "portal_types": {
          "IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatik, Softwareentwicklung, etc."])},
          "MINT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrotechnik, Maschinenbau, etc."])},
          "OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BWL, VWL, Recht, etc."])},
          "SANO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ärzte, Therapeuten, Pfleger, etc."])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Themen"])}
        },
        "query_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchbegriffe..."])},
        "remote_type": {
          "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid und 100% Mobiles Arbeiten"])},
          "full_remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100% Daheim (Mobiles Arbeiten, Deutschland)"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ohne Home-Office"])},
          "partial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid: Vor-Ort mit Home-Office-Anteil/Mobiles Arbeiten"])}
        },
        "search_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])}
      },
      "footer": {
        "datenschutz": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
        "impressum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
        "stellen_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stellen schalten"])}
      },
      "job_card": {
        "more_than_30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 30 Tage"])},
        "services_for_internationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services für Internationals:"])},
        "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
        "translated_content_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stellenbeschreibung liegt in diesen Sprachen vor"])}
      },
      "job_description_content": {
        "translate_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetze mit"])},
        "translations_of_this_job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übersetzungen dieser Stelle:"])}
      },
      "navbar": {
        "anzeige_schalten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Arbeitgeber"])}
      },
      "non_german_ip_interstitial": {
        "country_options": {
          "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EU/EWR/Schengen"])},
          "germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
          "international": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes Land"])}
        },
        "modal_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sieht so aus, als wärst du momentan nicht in Deutschland. Kannst du uns mitteilen, wo du dich aktuell aufhältst?"])},
        "notice": {
          "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Jobs4International.de wechseln"])},
          "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Angaben bezüglich Arbeitserlaubnis und Wohnsitz passen leider nicht zu den Anforderungen dieser Position. Wir empfehlen dir, auf Jobs4Internationals.de nach passenden Stellen zu suchen, bei denen diese Kriterien nicht zwingend sind. Dort werden Unternehmen gelistet, die Unterstützung bei der Beantragung einer Arbeitserlaubnis für Deutschland bieten und bei der Umsiedlung helfen.\n<br>\nNatürlich steht es dir frei, dich auch hier für diese Position zu bewerben. Allerdings ist die Chance, dass du keine Unterstützung erhältst und möglicherweise eine Absage bekommst, recht hoch.\n"])},
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wichtige Information zu deiner Bewerbung"])}
        },
        "work_permit_options": {
          "germany_eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ich besitze schon eine Arbeitserlaubnis für Deutschland oder ein anderes EU-Land."])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, ich besitze bisher keine Arbeitserlaubnis für Deutschland oder ein anderes EU-Land."])}
        }
      },
      "subscribe_newsletter_bar": {
        "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalte neue passende Stellenangebote zu deinen oben eingestellten Suchbegriffen. Diesen Newsletter kannst du jederzeit abbestellen.\nWeitere Hinweise findest du in unseren <a href=\"/datenschutz\" target=\"_blank\">Datenschutzhinweisen</a>.\n"])},
        "modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Stellen per E-Mail erhalten"])},
        "new_jobs_per_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Stellen per E-Mail erhalten"])},
        "subscribe_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnieren"])},
        "subscribed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wir haben eine E-Mail an ", _interpolate(_named("email")), " geschickt. Bitte klicke auf den darin enthaltenen Link um den Newsletter-Agenten zu starten. Bitte schaue ggf. auch im Ordner Spam/Junk nach."])}
      }
    },
    "elements": {
      "breadcrumbs": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home-Office-Jobs und Jobs mit mobiler Arbeit"])}
      },
      "pagination": {
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
        "info": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zeige <span class=\"font-medium\">", _interpolate(_named("from")), "</span> bis <span class=\"font-medium\">", _interpolate(_named("to")), "</span> von <span class=\"font-medium\">", _interpolate(_named("count")), "</span> Stellen"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])}
      },
      "upload_field": {
        "is_uploading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird hochgeladen:"])},
        "or_drag_and_drop_here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder hier herziehen und ablegen"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernen"])},
        "upload_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Dokument hochladen"])}
      }
    },
    "form": {
      "messages": {
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist keine gültige E-Mail-Adresse"])},
        "fileRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du musst mindestens eine Datei hochladen"])},
        "required": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("property")), " muss angegeben werden"])}
      },
      "properties": {
        "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar/Anschreiben"])},
        "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie Ihr Wohnland aus der Liste aus"])},
        "country_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ländervorwahl"])},
        "current_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo wohnst du zurzeit?"])},
        "current_work_permit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du schon eine Arbeitserlaubnis für Deutschland oder die EU?"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
        "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungsunterlagen"])},
        "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
        "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewünschte Anrede"])},
        "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
        "livingInGermany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wo befindet sich Ihr aktueller Wohnort?"])},
        "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
        "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
        "question_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewerbungskurzfrage"])},
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße & Hausnummer"])}
      }
    },
    "job_title_generator": {
      "at_homeoffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" im Home-Office"])},
      "at_homeoffice_with_countries": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([" im Home-Office (", _interpolate(_named("countries")), ")"])},
      "name_list_connector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])},
      "name_list_last_word_connector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sowie"])}
    },
    "meta_description": {
      "jobs": {
        "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STELLENBESCHREIBUNG"])},
        "intro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Das ist eine Stellenanzeige von ", _interpolate(_named("portal")), ". Den vollständigen Link zur Stellenanzeige findest du hier: <a href=\"", _interpolate(_named("url")), "\">", _interpolate(_named("url")), "</a>."])},
        "keywords": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Wichtige Schlagworte für diese Anzeige: ", _interpolate(_named("keywords"))])},
        "outro": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Es handelt sich hier um eine Stellenanzeige von ", _interpolate(_named("portal")), " (Mitglied des Empfehlungsbundes / Empfehlungsbund / EBND). Mit den Klick auf Bewerben gelangst du zur Originalanzeige auf ", _interpolate(_named("hostname")), "."])}
      }
    },
    "models": {
      "fallback_portal_search": {
        "result": {
          "home_office_jobs_gefunden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("pagy_count")), " Home-Office Jobs ", _interpolate(_named("q")), " gefunden"])},
          "keine_stellenangebote_gefu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Stellenangebote gefunden"])},
          "stellen_gefunden": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("pagy_count")), " Stellen ", _interpolate(_named("q")), " gefunden"])},
          "zu": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["zu \"", _interpolate(_named("qq")), "\""])},
          "zu2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["zu ", _interpolate(_named("query_competence_name"))])}
        }
      },
      "job": {
        "requirements_info": {
          "arbeitserlaubnis_in_deutsc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitserlaubnis in Deutschland bzw. Schengen/EU notwendig"])},
          "arbeitsort_muss_erreichbar": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Arbeitsort muss erreichbar sein: ", _interpolate(_named("location_list_to_sentence"))])},
          "einsatz_in_schichtarbeit_n": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einsatz in Schichtarbeit notwendig"])},
          "wohnort_muss_in_diesen_lae": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ihr Wohnort muss in einem dieser Länder liegen: ", _interpolate(_named("remote_countries_list"))])}
        }
      }
    },
    "pages": {
      "jobs": {
        "index": {
          "jobs4i": {
            "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen auf Jobs4Internations.de, dem Job-Portal für internationale Fachkräfte in und außerhalb Deutschlands. Hier findest du Stellenangeboten die einerseits z.B. keine hohe Sprachfähigkeiten in Deutsch voraussetzen oder von Firmen, die eine offene Willkommenskultur haben."])}
          }
        },
        "show": {
          "benefits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter-Benefits"])},
          "apply_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt bewerben"])},
          "ddf_member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sponsor des Datenbankforums"])},
          "member_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied im Silicon Saxony e.V."])}
        }
      },
      "jobs_invisible": {
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Stellenanzeige ist nicht mehr verfügbar und wurde archiviert."])},
        "more_jobs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ähnliche Stellenanzeigen"])},
        "open_jobs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Aktuelle Stellenanzeigen von ", _interpolate(_named("company")), " und anderen Firmen: "])}
      }
    },
    "salutation": {
      "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
      "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])}
    }
  }
}